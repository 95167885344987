var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.deletedProp)?_c('div',{staticClass:"w-full h-full overflow-y-scroll bg-gray-100 py-5",attrs:{"id":"details"}},[_c('h1',{staticClass:"my-title mb-3 text-center"},[_vm._v(" "+_vm._s(_vm.$t("adminPanel.locations.title"))+" ")]),_c('p',{staticClass:"mb-3 text-center"},[_vm._v(" "+_vm._s(_vm.$t("adminPanel.locations.sentToTrash"))+" ")])]):_c('div',{staticClass:"w-full h-full overflow-y-scroll bg-gray-100 py-5",staticStyle:{"overscroll-behavior":"contain"},attrs:{"id":"details"}},[_c('h1',{staticClass:"my-title mb-3 text-center"},[_vm._v(" "+_vm._s(_vm.$t("adminPanel.locations.title"))+" ")]),(_vm.getIsLoadingLocationDetails)?_c('div',{staticClass:"flex justify-center"},[_c('SpinerComponent')],1):(!_vm.locationData)?_c('div',[_c('h3',{staticClass:"my-title-2"},[_vm._v(_vm._s(_vm.$t("adminPanel.locations.select")))])]):[_c('div',{staticClass:"w-2/3 mx-auto"},[_c('div',{staticClass:"w-full flex justify-between"},[_c('router-link',{attrs:{"to":{
            name: 'admin-locations-detail-info',
            params: { id: _vm.idLocation },
            hash: '#details',
          },"active-class":"text-my-blue-primary","exact":""}},[_vm._v(_vm._s(_vm.$t("adminPanel.locations.details")))]),_c('router-link',{attrs:{"to":{
            name: 'admin-locations-detail-images',
            hash: '#details',
          },"active-class":"text-my-blue-primary","exact":""}},[_vm._v(_vm._s(_vm.$t("adminPanel.locations.images")))]),_c(_vm.locationData.propertyType === 'entire' ? 'span' : 'router-link',{tag:"router-link",class:_vm.locationData.propertyType==='entire' && 'my-disabled',attrs:{"to":{
            name: 'admin-locations-detail-rooms',
            params: { id: _vm.idLocation },
            hash: '#details',
          },"active-class":"text-my-blue-primary","exact":""}},[_vm._v(_vm._s(_vm.$t("adminPanel.locations.seeRooms")))]),_c(_vm.locationData.propertyType === 'entire' ? 'span' : 'router-link',{tag:"router-link",class:_vm.locationData.propertyType==='entire' && 'my-disabled',attrs:{"to":{
            name: 'admin-locations-detail-rooms-create',
            params: { id: _vm.idLocation },
            hash: '#details',
          },"active-class":"text-my-blue-primary"}},[_vm._v(_vm._s(_vm.$t("adminPanel.locations.newRoom")))])],1)]),_c('router-view')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }